import React, { useState } from 'react';
import projects from '../data/projectData';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ProjectCard from '../components/projectCard';
import AnimatedObject from '../components/AnimatedObject';
import "../css/main.css";

function ProjectsPage() {
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  // Get all unique project categories
  const allCategories = [...new Set(projects.map((project) => project.category)), "All Projects"];

  // Sort projects in descending order
  const sortedProjects = [...projects].sort((a, b) => b.id - a.id);

  return (
    <div>
      <CustomNavbar />
      <Header 
        title="Projects"
        subtext="Discover our projects where we proudly display an extensive array of services. From steel erection and supply and application to anchor bolt installation, fire paint systems, and shear stud implementation, our track record speaks volumes."
        imgURL="/images/banners/banner-project-01.webp"
      />
      <section className='projects-section'>
        <div className='project-categories'>
          {allCategories.map((category) => (
            <button 
              key={category}
              onClick={() => handleCategoryClick(category)}
              className={selectedCategory === category ? 'active btn-cat btn-cat-active' : 'btn-cat' }
            >
              {category}
            </button>
          ))}
        </div>

        <div className='project-cards'>
          {sortedProjects
            .filter((project) => selectedCategory === 'All Projects' || selectedCategory === '' || project.category === selectedCategory)
            .map((project) => (
              <ProjectCard 
                key={project.id}
                id={project.id}
                title={project.title}
                imgURL={project.images.mainImage}
              />
            ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ProjectsPage;
