import React from 'react';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import "../css/main.css"
import "../css/qr.css"


function Qr() {


  return (
    <div>
    
    <CustomNavbar />
    <Header 
      title = "Elite Signature Contracting"
      subtext = ""
      imgURL = "/images/banners/banner-about-01.webp"
    />

    <div className='info-section'>
    <div className='info-col'>
    <div className='info-card'>
    <div className='info-header'>
    <img src='/images/qr/linkedin.webp'/>
    <h3>LinkedIn Page</h3>
    </div>
    <Link to="https://www.linkedin.com/company/elite-signature-contracting" target="_blank" rel="noopener noreferrer" className='button-info'>
    <button className='button'>Visit Page</button>
    </Link>
    </div>
    <div className='info-card'>
    <div className='info-header'>
    <img src='/images/qr/company.jpeg'/>
    <h3>Company Profile</h3>
    </div>
    {/* <Link to="/files/company-profile.pdf"  download="company-profile.pdf" target="_blank" rel="noopener noreferrer" className='button-info'>
    <button className='button'>Download</button>
    </Link> */}

    <a className='button-info' href="/files/Elite signature company profile - Digital.pdf" target='_blank'>
    <button className='button'>Download</button>
    </a>


    </div>
    </div>
    <div className='info-col'>
    <div className='info-video-container'>
    <p>Discover the essence of Elite Signature Contracting in this engaging video! We take immense pride in being at the forefront of crafting remarkable projects across Saudi Arabia.</p>
    <iframe className="info-video" src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7112458358082686976?compact=1"  frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
    </div>
    </div>
    </div>
    <Footer />
    </div>

    
  );
}

export default Qr;

