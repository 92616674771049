import React from 'react';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ServiceCard from '../components/serviceCard';
import services from '../data/serviceData';
import "../css/main.css"



function Services() {

  const featuredServices = services
  .filter((services) => services.featured)
  .sort((a, b) => a.featured - b.featured)


  return (
    <div>
    <CustomNavbar />

    <Header 
      title = "Services"
      subtext = "As a leading contracting company, we offer a comprehensive range of solutions for all your industrial needs. With a dedicated team of experts, we ensure meticulous planning and seamless execution, prioritizing quality, safety, and client satisfaction."
      imgURL = "/images/banners/banner-service-01.webp"
    />
    <div className='services-section service'>
      <div className='service-cards'>
      {featuredServices.map((services) => (
        <ServiceCard
          id={services.id}
          title={services.title}
          imgURL={services.imgURL}
      />
      ))}
      </div>
      </div>
    <Footer />
    </div>
  );
}

export default Services;

