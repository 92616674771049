const news = [
    {
        id: 1,
        title: "Elite Signature Contracting Awarded Neom & Sharma Construction Villages Project",
        content: "NEOM, the visionary city in Saudi Arabia, is collaborating with Al-Fanar and AIC to create the Neom & Sharma Construction Villages project. Spanning 139,000m2, this initiative utilizes pre-engineered buildings, emphasizing sustainability in line with NEOM's commitment to innovation and eco-conscious practices. Elite Signature Contracting has been awarded the prestigious role of contributing to this groundbreaking project, further reinforcing its significance in shaping NEOM's future.",
        imgURL: "/images/news/01-neom-construction-village.webp",
    },
    {
        id: 2,
        title: "Elite Signature Contracting Awarded Al-Jubail Desalination Plant Phase 2 Project",
        content: "SWCC (Saline Water Conversion Corporation) takes another stride towards technological advancement by collaborating with S.S.E.M and AIC for the Replacement of Technologies and Ended Lifetime Assets at Al-Jubail Desalination Plant Phase 2. With a total tonnage of 2500 tons, this ambitious project involves the installation of Pre-Engineered Buildings (PEBs) for workshop and warehouse buildings in Jubail. Elite Signature Contracting's recognition as the main contractor further solidifies the significance of this endeavor in fostering a sustainable future for water conversion infrastructure.",
        imgURL: "/images/news/02-al-jubail-desalination-plant.webp",
    },
    {
        id: 3,
        title: "Elite Signature Contracting Awarded a Major Project with Aqua Bridge Company",
        content: "Aqua Bridge Company and Elite Signature Contracting are joining forces once again for a remarkable project. With a total area of 3000 square meters, this collaboration involves the supply and installation of a Pre-Engineered Building (PEB) warehouse facility in the futuristic city of Neom. The steel for this project, proudly manufactured by AIC, reinforces its commitment to sustainable construction practices. The significance of this venture lies in its contribution to Neom's ever-evolving infrastructure, bringing it one step closer to becoming a beacon of technological progress and environmental sustainability.",
        imgURL: "/images/news/03-aqua-bridge.webp",
    },  
    {
        id: 4,
        title: "Elite Signature Contracting awarded MBC Studio Project! ",
        content: "Exciting Update! Elite Signature Contracting is thrilled to be at the heart of the action, working alongside East Delta Saudi Co. Ltd. and AFCO STEEL on the latest MBC Studio, the iconic venue for next year's Arabs Got Talent! In just 10 days of on-site dedication, our team has set the stage for something truly spectacular. From blueprint to reality, witness the progress as we bring innovation to every corner of this groundbreaking project. We extend our gratitude to MBC GROUP and their wonderful COO Mr. Joe Igoe for entrusting us with this incredible opportunity. Stay tuned for regular updates as we shape the future of entertainment! ",
        imgURL: "/images/news/04-mbc.jpeg",
    },
    {
        id: 5,
        title: "Safety First at Elite Signature Contracting LLC! ",
        content: "At Elite Signature Contracting LLC, we take immense pride in cultivating a culture of safety on every project. We're proud of our dedicated team that has been recognized by Group AMANA for their outstanding safety practices on the SANKYU Saudi Arabia's Maintenance and Resource Development Center project in Jubail. Safety isn't just a requirement; it's a commitment to each other's welfare. Together, we build with care, ensuring a secure environment for everyone involved.",
        imgURL: "/images/news/05-safety.jpeg",
    },
    {
        id: 6,
        title: "MBC Studio Project Update: Moving Closer to Completion! ",
        content: "We are delighted to announce that the MBC Studio Project, the iconic venue for this year's Arabs got talent, is entering the final phase of handover, marking a significant milestone in our journey. As we approach the finish line, we reflect on the incredible teamwork and shared commitment that have shaped this success. Here's to the exciting new chapter that awaits MBC Studio! ",
        imgURL: "/images/news/06-mbc.jpeg",
    },
    {
        id: 7,
        title: "A work in progress. SANKYU Saudi Arabia's Maintenance and Resource Development Center",
        content: "Elite Signature Contracting is excited to share the ongoing advancements in our collaboration with Group AMANA for the Erection of the Steel Structure at SANKYU Saudi Arabia's Maintenance and Resource Development Center in Jubail, Eastern Province, KSA. Our dedicated team continues to work tirelessly, pushing boundaries to bring to life this remarkable 6,000m2 facility. Jubail is witnessing the evolution of SANKYU's vision into a tangible and awe-inspiring reality.",
        imgURL: "/images/news/07-sankyu.jpeg",
    },
    {
        id: 8,
        title: "Progress Update at Al-Jubail Desalination Plant Phase 2!",
        content: "Exciting strides are being made in our ongoing collaboration with SWCC_KSA, S.S.E.M, and AIC Steel for the Replacement of Technologies and Ended Lifetime Assets at Al-Jubail Desalination Plant Phase 2. With a total tonnage of 2500 tons, our team at Elite Signature Contracting is making significant progress in installing Pre-Engineered Buildings (PEBs) for workshop and warehouse facilities in Jubail. As the main contractor, we're proud to contribute to this transformative project, marking a crucial step forward in advancing water conversion infrastructure sustainably. Stay tuned for more updates as we continue to shape the future of this vital endeavor! ",
        imgURL: "/images/news/08-jubail.jpeg",
    },
    {
        id: 9,
        title: "Milestone Achieved: The Empty Quarter Project",
        content: "We are thrilled to announce a significant milestone in the Empty Quarter Project on the borders between KSA and Oman. The dream is now a tangible reality as we've successfully completed the installation of Light Gauge Steel (LGS). Our dedicated team worked tirelessly day and night to achieve this remarkable goal.",
        imgURL: "/images/news/09-empty-square.jpeg",
    },
    {
        id: 10,
        title: "Progress in Partnership with Al Fanar Projects at NEOM!",
        content: "Embark on a journey of progress with Elite Signature Contracting as we bring you thrilling updates from our ground breaking projects in collaboration with Alfanar Projects, right in the heart of NEOM in partnership with esteemed steel manufacturers AIC Steel. Our commitment to innovation, sustainability, and shaping the future continues to evolve. Stay tuned as we share the latest snapshots of our remarkable journey. ",
        imgURL: "/images/news/10-fanar.jpeg",
    },
    {
        id: 11,
        title: "SANKYU's Maintenance and Resource Development Center - Project Complete ",
        content: "We are thrilled to announce the successful completion of the Erection of the Steel Structure at SANKYU's Maintenance and Resource Development Center in Jubail, Eastern Province, KSA in collaboration with Group AMANA. It's been an incredible journey marked by dedication, teamwork, and unwavering commitment to excellence. Our heartfelt gratitude goes out to the entire team whose hard work and determination have brought this project to fruition. Special thanks to our partners at SANKYU Saudi Arabia for their trust and collaboration. This state-of-the-art facility stands as a testament to our collective vision and passion for innovation.",
        imgURL: "/images/news/11-sankyu.jpeg",
    },
    {
        id: 12,
        title: "Elite Signature Contracting awarded SPORTPLEX project in Jeddah ",
        content: "We're thrilled to announce that Elite Signature Contracting LLC has been awarded the prestigious SPORTPLEX project in Jeddah. This monumental sports infrastructure project is part of the kingdom’s entertainment vision. The project involves the supply and erection of steel structure and sandwich panels. The complex is 4 floors with a height of 30 meters. The structure system is an outstanding 56 meter clear span truss section. This cutting-edge facility will redefine the standards of sports infrastructure in the region. We're proud to be at the forefront of this ambitious project, bringing our expertise and dedication to ensure its success. Stay tuned for updates as we embark on this exciting journey to build Jeddah's premier sports complex! ",
        imgURL: "/images/news/12-sportplex.jpeg",
    },
    
];

export default news;