import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
  const form = useRef();
  const [submissionStatus, setSubmissionStatus] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    if (form.current.checkValidity()) {
      emailjs
        .sendForm('service_d0hzn5v', 'template_9rkf37a', form.current, '2DqOUdUI11Q-_WXBd')
        .then(
          (result) => {
            console.log(result.text);
            setSubmissionStatus('Success! Your message has been sent.');
            form.current.reset(); // Clear form fields
          },
          (error) => {
            console.log(error.text);
            setSubmissionStatus('Error! Failed to send the message.');
          }
        );
    } else {
      // Form is invalid, handle error or show validation messages
      console.log('Form is invalid');
    }
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="styled-input wide">
        <input type="text" required name="user_name" />
        <label>Name</label>
      </div>

      <div className="styled-input">
        <input type="email" required name="user_email" />
        <label>E-mail</label>
      </div>

      <div className="styled-input">
        <input type="tel" required name="user_phone" pattern="[0-9]{10-15}" />
        <label>Phone Number</label>
      </div>

      <div className="styled-input wide">
        <textarea required name="user_message"></textarea>
        <label>Message</label>
      </div>

      <div className='submit-area'>
      <input className="button" type="submit" value="Send" />
      {submissionStatus && <p>{submissionStatus}</p>}
      </div>
    </form>
  );
};





// export const ContactUs = () => {

  

//   return (
//     <form id="contactform" action="https://formsubmit.io/send/2d58295a-feff-4ee8-a936-79f5ecd3d865" method="POST">
   
//                     <div className="styled-input wide">
//                       <input type="text" required name="user_name"/>
//                       <label>Name</label> 
                
//                   </div>
                  
//                     <div className="styled-input">
//                       <input type="text" required name="user_email"/>
//                       <label>E-mail</label> 
                  
//                   </div>
                  
//                     <div className="styled-input" >
//                       <input type="text" required name="user_phone"/>
//                       <label>Phone Number</label> 
                    
//                   </div>
                  
//                     <div className="styled-input wide">
//                       <textarea required name="user_message"></textarea>
//                       <label>Message</label>
                    
//                   </div>
    
//       <input className='button' type="submit" value="Send" />
//     </form>
//   );
// };






    // 2d58295a-feff-4ee8-a936-79f5ecd3d865




//     import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';

// export const ContactUs = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm('service_s85n7hf', 'template_w1po2zs', form.current, 'TgOfbVcWR-CS4IJsM')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//   };

//   return (
//     <form ref={form} onSubmit={sendEmail}>
   
//                     <div className="styled-input wide">
//                       <input type="text" required name="user_name"/>
//                       <label>Name</label> 
                
//                   </div>
                  
//                     <div className="styled-input">
//                       <input type="text" required name="user_email"/>
//                       <label>E-mail</label> 
                  
//                   </div>
                  
//                     <div className="styled-input" >
//                       <input type="text" required name="user_phone"/>
//                       <label>Phone Number</label> 
                    
//                   </div>
                  
//                     <div className="styled-input wide">
//                       <textarea required name="user_message"></textarea>
//                       <label>Message</label>
                    
//                   </div>
         
    
//       <input className='button' type="submit" value="Send" />
//     </form>
//   );
// };